<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3327 9.33223L15.8453 6.35779C15.4172 5.50174 15.2032 5.07369 14.8838 4.76098C14.6015 4.48443 14.2611 4.27411 13.8874 4.14524C13.4649 3.99951 12.9863 3.99951 12.0291 3.99951H6.93268C5.43921 3.99951 4.69247 3.99951 4.12204 4.29013C3.62028 4.54576 3.21233 4.95366 2.95666 5.45537C2.66602 6.02573 2.66602 6.77239 2.66602 8.26569V9.33223M2.66602 9.33223H22.9327C25.1729 9.33223 26.293 9.33223 27.1486 9.76815C27.9013 10.1516 28.5132 10.7635 28.8967 11.516C29.3327 12.3716 29.3327 13.4915 29.3327 15.7315V21.5975C29.3327 23.8374 29.3327 24.9574 28.8967 25.813C28.5132 26.5655 27.9013 27.1774 27.1486 27.5608C26.293 27.9967 25.1729 27.9967 22.9327 27.9967H9.06602C6.82581 27.9967 5.7057 27.9967 4.85005 27.5608C4.09741 27.1774 3.48548 26.5655 3.10199 25.813C2.66602 24.9574 2.66602 23.8374 2.66602 21.5975V9.33223Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconFolderOutline"
}
</script>
